require('./theme/jquery-3.3.1.min');
require('./theme/plugins-jquery');
require('./theme/custom');



if($('#admin-content').length > 0)
{
    let tinymce = require('tinymce/tinymce');
    require('tinymce/themes/modern/theme');
    require('tinymce/plugins/lists');
    require('tinymce/plugins/link');
    require('tinymce/plugins/code');
    require('tinymce/plugins/image');

    tinymce.init({
        selector: '.tinymce-editor',
        skin_url: '/assets/tinymce/lightgray',
        plugins: ['link', 'lists', 'code', 'image']
    });
}

if($('#img-startpage').length > 0)
{
    $('.nav-startpage-slider').on('click', function () {
        $('#img-startpage').attr('src', '/assets/bild-startseite-' + $(this).data('id') + '.jpg')
    });
}