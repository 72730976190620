(function($){
  "use strict";
  var POTENZA = {};

  /*************************
  Predefined Variables
*************************/ 
var $window = $(window),
    $document = $(document);


/*************************
      tooltip
*************************/
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  

/*************************
       Mega menu
*************************/    
POTENZA.megaMenu = function () {

    require('./mega_menu');

    var nav =  $('#menu');
    var l = nav.attr('data-pos');
    var pos = 'horizontal';
    if(l) pos = l;
    nav.megaMenu({
          // DESKTOP MODE SETTINGS
          logo_align          : 'left',         // align the logo left or right. options (left) or (right)
          links_align         : 'left',         // align the links left or right. options (left) or (right)
          socialBar_align     : 'left',         // align the socialBar left or right. options (left) or (right)
          searchBar_align     : 'right',        // align the search bar left or right. options (left) or (right)
          trigger             : 'hover',        // show drop down using click or hover. options (hover) or (click)
          effect              : 'expand-top',   // drop down effects. options (fade), (scale), (expand-top), (expand-bottom), (expand-left), (expand-right)
          effect_speed        : 400,            // drop down show speed in milliseconds
          sibling             : true,           // hide the others showing drop downs if this option true. this option works on if the trigger option is "click". options (true) or (false)
          outside_click_close : true,           // hide the showing drop downs when user click outside the menu. this option works if the trigger option is "click". options (true) or (false)
          top_fixed           : false,          // fixed the menu top of the screen. options (true) or (false)
          sticky_header       : true,           // menu fixed on top when scroll down down. options (true) or (false)
          sticky_header_height: 250,            // sticky header height top of the screen. activate sticky header when meet the height. option change the height in px value.
          menu_position       : pos,            // change the menu position. options (horizontal), (vertical-left) or (vertical-right)
          full_width          : false,          // make menu full width. options (true) or (false)
         // MOBILE MODE SETTINGS
          mobile_settings     : {
            collapse            : true,         // collapse the menu on click. options (true) or (false)
            sibling             : true,         // hide the others showing drop downs when click on current drop down. options (true) or (false)
            scrollBar           : true,         // enable the scroll bar. options (true) or (false)
            scrollBar_height    : 400,          // scroll bar height in px value. this option works if the scrollBar option true.
            top_fixed           : false,        // fixed menu top of the screen. options (true) or (false)
            sticky_header       : false,         // menu fixed on top when scroll down down. options (true) or (false)
            sticky_header_height: 200           // sticky header height top of the screen. activate sticky header when meet the height. option change the height in px value.
         }
    });
};
POTENZA.mobileview = function () {
    $('a.but').on("click", function (e) {
        if(!$(this).hasClass('active')){
          $(this).addClass('active');
          $('#left-header').animate({'margin-left':230},300);
        } else {
          $(this).removeClass('active');
          $('#left-header').animate({'margin-left':0},300);
        }
      return false;
    });
};

POTENZA.stickyFooter = function () { 
    var $footerfixed = $("#footer-fixed");
    if ($footerfixed.length > 0) {
        var footerheight = $('#footer-fixed').height();
        $('.wrapper').css('margin-bottom', (footerheight + "px"))
    }
};

POTENZA.carousel = function () {
    var owlslider = jQuery("div.owl-carousel");
    if(owlslider.length > 0) {
        require('./owl-carousel.min');
        owlslider.each(function () {
            var $this = $(this),
                $items = ($this.data('items')) ? $this.data('items') : 1,
                $loop = ($this.attr('data-loop')) ? $this.data('loop') : true,
                $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
                $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
                $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
                $autospeed = ($this.attr('data-autospeed')) ? $this.data('autospeed') : 5000,
                $smartspeed = ($this.attr('data-smartspeed')) ? $this.data('smartspeed') : 1000,
                $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
                $space = ($this.attr('data-space')) ? $this.data('space') : 30;

            $(this).owlCarousel({
                loop: $loop,
                items: $items,
                responsive: {
                    0:{items: $this.data('xx-items') ? $this.data('xx-items') : 1},
                    480:{items: $this.data('xs-items') ? $this.data('xs-items') : 1},
                    768:{items: $this.data('sm-items') ? $this.data('sm-items') : 2},
                    980:{items: $this.data('md-items') ? $this.data('md-items') : 3},
                    1200:{items: $items}
                },
                dots: $navdots,
                autoplayTimeout:$autospeed,
                smartSpeed: $smartspeed,
                autoHeight:$autohgt,
                margin:$space,
                nav: $navarrow,
                navText:["<i class='fa fa-angle-left fa-2x'></i>","<i class='fa fa-angle-right fa-2x'></i>"],
                autoplay: $autoplay,
                autoplayHoverPause: true
            });
        });
    }
};

POTENZA.megaMenu();
POTENZA.stickyFooter();
POTENZA.mobileview();
POTENZA.carousel();

})(jQuery);